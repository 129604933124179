<template>
  <v-app>
    <v-container class="fill-height">
      <v-card
        class="col-md-10 offset-md-1 col-lg-10 offset-lg-1"
        elevation="12"
      >
        <v-alert light class="text-center">
          <img
            style="width: 150px"
            src="https://sistem.xysinav.com/Resimler/Sirket/WhatsApp-Image-2022-12-16-at-17.42.24.jpeg"
            alt="Profesyonel Bursluluk Akademisi"
          />
        </v-alert>
        <v-alert color="blue-grey" dark class="text-center">
          <h2>Bursluluk Kayıt Formu</h2>
        </v-alert>
        <v-expand-transition mode="out-in">
          <v-row justify="center" v-show="adim1">
            <v-col cols="12">
              <div class="text-center font-weight-bold">
                <h3>Kişisel Bilgiler</h3>
              </div>
              <v-divider></v-divider>
            </v-col>
            <!-- <v-col cols="10" sm="10" md="10" class="mt-2">
              <v-text-field
                type="text"
                v-model="kullaniciAdi"
                rounded
                outlined
                dense
                append-icon="mdi-account-box"
                name="input-10-2"
                label="Kullanıcı Adı"
                :rules="[rules.required]"
                :error-messages="errorMessage"
                :color="kullaniciRenk"
                @change="kullaniciAdiKontrol"
              ></v-text-field>
            </v-col> -->
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                label="Adınız"
                v-model="ad"
                rounded
                outlined
                dense
                name="name"
                append-icon="mdi-account-multiple-outline"
                type="text"
                :rules="[rules.required]"
                required
              />
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                label="Soyadınız"
                v-model="soyad"
                rounded
                outlined
                dense
                name="name"
                append-icon="mdi-account-multiple"
                type="text"
                required
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                label="E-posta"
                v-model="eposta"
                rounded
                outlined
                dense
                name="posta"
                append-icon="mdi-email"
                type="text"
                :error-messages="epostaMessage"
                :rules="[rules.required, rules.email]"
                :color="epostaRenk"
                @change="ePostaKontol"
                required
            /></v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                label="Telefon"
                v-model="telefon"
                rounded
                outlined
                dense
                name="tel"
                append-icon="mdi-cellphone"
                type="number"
                :hint="true ? 'Başında 0 olmadan yazınız' : ''"
                required
                :error-messages="telefonMessage"
                @change="telKontrol"
                counter="10"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-expand-transition mode="out-in">
          <v-row justify="center" v-show="adim2">
            <v-col cols="10">
              <div class="text-center font-weight-bold">
                <h3>Okul Bilgileri</h3>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" sm="10" md="5" class="mt-2">
              <v-autocomplete
                rounded
                outlined
                dense
                type="text"
                append-icon="mdi-account-box"
                name="input-10-2"
                label="İl"
                :items="iller"
                item-text="ad"
                item-value="sehirId"
                v-model="ilId"
                :rules="[rules.required]"
                @change="ilceGetir"
              ></v-autocomplete>
            </v-col>
            <v-col cols="10" sm="10" md="5" class="mt-2">
              <v-autocomplete
                rounded
                outlined
                dense
                label="İlçe"
                name="name"
                append-icon="mdi-account-multiple-outline"
                type="text"
                required
                :items="ilceler"
                item-text="ad"
                item-value="ilceId"
                :rules="[rules.required]"
                v-model="ilceId"
                @change="okulGetir"
                :disabled="ilId == null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-autocomplete
                rounded
                outlined
                dense
                label="Okul"
                name="okul"
                append-icon="mdi-school"
                type="text"
                :items="okullar"
                item-text="okulAd"
                item-value="okulId"
                v-model="okulId"
                :rules="[rules.required]"
                :disabled="ilceId == null"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                rounded
                outlined
                dense
                label="Okul Numaranız"
                name="name"
                append-icon="mdi-account-multiple-outline"
                type="number"
                v-model="okulNo"
                :rules="[rules.required]"
                required
            /></v-col>
            <v-col cols="10" sm="10" md="5">
              <!-- <v-autocomplete
                rounded
                outlined
                dense
                label="Şube"
                name="sube"
                append-icon="mdi-school"
                type="text"
                :items="subeler"
                item-text="subeAd"
                item-value="subeId"
                v-model="subeId"
                :rules="[rules.required]"
                :disabled="okulId == null"
                @change="sinifGetir"
                required
              ></v-autocomplete> -->

              <v-select
                rounded
                outlined
                dense
                label="Sınıf"
                name="sinif"
                append-icon="mdi-google-classroom"
                type="number"
                :items="siniflar"
                item-text="sinifAd"
                item-value="sinifId"
                v-model="sinifId"
                :rules="[rules.required]"
                :disabled="okulId == null"
                required
              ></v-select>
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-autocomplete
                rounded
                outlined
                dense
                label="Saat"
                name="Saat"
                append-icon="mdi-alphabetical-variant"
                type="text"
                :items="sinifGrubunaGoreSaatListesi"
                item-text="saat"
                item-value="id"
                v-model="sube"
                :rules="[rules.required]"
                :disabled="sinifId == null"
                required
              ></v-autocomplete>

              <!-- <v-text-field
                rounded
                outlined
                dense
                label="Şubeniz"
                placeholder="A - B - C şeklinde"
                name="name"
                append-icon="mdi-alphabetical-variant"
                type="text"
                @keyup="buyukHarf"
                v-model="sube"
                :rules="[rules.required]"
                required
              /> -->
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-expand-transition mode="out-in">
          <v-row justify="center" v-show="adim3">
            <v-col cols="10">
              <div class="text-center font-weight-bold">
                <h3>Güvenlik Bilgileri</h3>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" sm="10" md="5" class="mt-2">
              <v-text-field
                rounded
                outlined
                dense
                label="Parola"
                append-icon="mdi-lock"
                id="password"
                type="password"
                name="password"
                v-model="sifre"
              />
            </v-col>
            <v-col cols="10" sm="10" md="5" class="mt-2">
              <v-text-field
                rounded
                outlined
                dense
                label="Parola Tekrar"
                append-icon="mdi-lock"
                name="password"
                type="password"
                v-model="sifreTekrar"
                required
              />
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-img
                :src="'data:image/jpeg;base64,' + captchaCode"
                width="200"
                class="mx-auto"
              />
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                rounded
                outlined
                dense
                label="Doğrulama Kodu"
                append-icon="mdi-rotate-right"
                v-model="captcha"
              ></v-text-field
            ></v-col>
            <!-- <v-col cols="10" sm="10" md="10">
              <v-checkbox>
                <template v-slot:label>
                  <div>
                    <template>
                      <a href="#" @click.stop="alerts1()">
                        Üyelik Sözleşmesi ve Çerez politikasını
                      </a>
                    </template>
                    kabul ediyorum.
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox>
                <template v-slot:label>
                  <div>
                    <template>
                      <a
                        target="_blank"
                        href="http://vuetifyjs.com"
                        @click.stop
                      >
                        KVKK aydınlatma metnini okudum
                      </a>
                    </template>
                    ve kabul ediyorum.
                  </div>
                </template>
              </v-checkbox>
            </v-col> -->
          </v-row>
        </v-expand-transition>
        <v-card-actions>
          <v-btn v-if="!adim1" color="warning" @click="onceki"
            ><v-icon> mdi-arrow-left</v-icon>Önceki</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn v-if="!adim3" color="success" @click="dolulukKontrol"
            >Sonraki <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
          <v-btn v-if="adim3" color="success" @click="sifreKontrol"
            >Kaydı Tamamla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
export default {
  name: 'OnlineKayit',
  data() {
    return {
      modal: false,
      modalHata: null,
      modalBaslik: null,
      adim1: true,
      adim2: false,
      adim3: false,
      studentClass: [
        // { id: 1, name: '1. sınıf' },
        // { id: 2, name: '2. sınıf' },
        // { id: 3, name: '3. sınıf' },
        // { id: 4, name: '4. sınıf' },
        // { id: 5, name: '5. sınıf' },
        // { id: 6, name: '6. sınıf' },
        // { id: 7, name: '7. sınıf' },
        // { id: 8, name: '8. sınıf' },
        { id: 9, name: '9. sınıf' },
        { id: 10, name: '10. sınıf' },
        { id: 11, name: '11. sınıf' },
        { id: 12, name: '12. sınıf' },
        { id: 13, name: 'Mezun' },
      ],
      kullaniciAdi: '',
      ad: '',
      soyad: '',
      eposta: '',
      telefon: '',
      ilId: null,
      ilceId: null,
      okulId: null,
      sinifId: null,
      subeId: 2958,
      sirketId: 632,
      sube: '',
      okulNo: '',
      sifre: '',
      sifreTekrar: '',
      captcha: '',
      kullaniciRenk: null,
      epostaRenk: null,
      telefonRenk: null,
      errorMessage: null,
      epostaMessage: null,
      telefonMessage: null,
      rules: {
        required: (value) => !!value || 'Zorunlu Alan.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Hatalı eposta girdiniz.';
        },
      },
      sinifSaatListesi: [
        {
          id: 1,
          sinifGrubu: 6,
          saat: 'CUMARTESİ 12:00',
        },
        {
          id: 2,
          sinifGrubu: 7,
          saat: 'CUMARTESİ 12:00',
        },
        {
          id: 3,
          sinifGrubu: 9,
          saat: 'CUMARTESİ 10:00',
        },
        {
          id: 4,
          sinifGrubu: 10,
          saat: 'CUMARTESİ 10:00',
        },
        {
          id: 5,
          sinifGrubu: 11,
          saat: 'CUMARTESİ 10:00',
        },
        {
          id: 6,
          sinifGrubu: 5,
          saat: 'PAZAR 10:00',
        },
        {
          id: 7,
          sinifGrubu: 6,
          saat: 'PAZAR 10:00',
        },
        {
          id: 8,
          sinifGrubu: 7,
          saat: 'PAZAR 10:00',
        },
        {
          id: 9,
          sinifGrubu: 9,
          saat: 'PAZAR 12:00',
        },
        {
          id: 10,
          sinifGrubu: 10,
          saat: 'PAZAR 12:00',
        },
        {
          id: 11,
          sinifGrubu: 11,
          saat: 'PAZAR 10:00',
        },
        {
          id: 12,
          sinifGrubu: 6,
          saat: 'CUMARTESİ 14:00',
        },
        {
          id: 13,
          sinifGrubu: 7,
          saat: 'CUMARTESİ 14:00',
        },
        {
          id: 14,
          sinifGrubu: 9,
          saat: 'CUMARTESİ 16:00',
        },
        {
          id: 15,
          sinifGrubu: 10,
          saat: 'CUMARTESİ 16:00',
        },
        {
          id: 16,
          sinifGrubu: 11,
          saat: 'CUMARTESİ 16:00',
        },
        {
          id: 17,
          sinifGrubu: 5,
          saat: 'PAZAR 16:00',
        },
        {
          id: 18,
          sinifGrubu: 6,
          saat: 'PAZAR 14:00',
        },
        {
          id: 19,
          sinifGrubu: 7,
          saat: 'PAZAR 14:00',
        },
        {
          id: 20,
          sinifGrubu: 9,
          saat: 'PAZAR 14:00',
        },
        {
          id: 21,
          sinifGrubu: 10,
          saat: 'PAZAR 14:00',
        },
        {
          id: 22,
          sinifGrubu: 11,
          saat: 'PAZAR 16:00',
        },
        {
          id: 23,
          sinifGrubu: 11,
          saat: 'PAZAR 12:00',
        },
        {
          id: 24,
          sinifGrubu: 7,
          saat: 'PAZAR 16:00',
        },
      ],
    };
  },
  created() {
    this.$vuetify.theme.isDark = false;
    this.$store.dispatch('CAPTCHA_CODE');
    this.$store.dispatch('REGISTER_GET_CITIES');
    this.$store.dispatch('GET_COMPANY_CLASSES', this.sirketId);

    this.sinifGetir();
  },
  methods: {
    // async kullaniciAdiKontrol() {
    //   if (this.kullaniciAdi != "") {
    //     await this.$store
    //       .dispatch("REGISTER_USER_CONTROL", this.kullaniciAdi)
    //       .then(() => {
    //         if (this.kullaniciControl != null) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Kayıtlı Kullanıcı",
    //             text: this.kullaniciControl,
    //             confirmButtonText: "Kapat",
    //           });
    //           this.kullaniciRenk = "red";
    //           this.errorMessage = this.kullaniciControl;
    //         } else {
    //           this.errorMessage = null;
    //           this.kullaniciRenk = "green";
    //         }
    //       });
    //   }
    // },
    async ePostaKontol() {
      if (this.eposta != '') {
        await this.$store
          .dispatch('REGISTER_USER_MAIL', this.eposta)
          .then(() => {
            if (this.epostaKontrol != null) {
              this.$swal({
                icon: 'info',
                title: 'Kayıtlı kullanıcı',
                text: this.epostaKontrol,
                confirmButtonText: 'Kapat',
              });
              this.epostaRenk = 'red';
              this.epostaMessage = this.epostaKontrol;
            } else {
              this.epostaMessage = null;
              this.epostaRenk = 'green';
            }
          });
      }
    },
    async telKontrol() {
      return false;
      // if (this.telefon != '') {
      //   await this.$store
      //     .dispatch('REGISTER_USER_PHONE', this.telefon)
      //     .then(() => {
      //       if (this.telefonKontrol != null) {
      //         this.$swal({
      //           icon: 'info',
      //           title: 'Kayıtlı cep telefonu',
      //           text: this.telefonKontrol,
      //           confirmButtonText: 'Kapat',
      //         }).then(() => {
      //           this.adim1 = true;
      //           this.adim2 = false;
      //           this.adim3 = false;
      //         });
      //         this.telefonRenk = 'red';
      //         this.telefonMessage = this.telefonKontrol;
      //       } else {
      //         this.telefonMessage = null;
      //         this.telefonRenk = 'green';
      //       }
      //     });
      // }
    },
    async ilceGetir() {
      await this.$store.dispatch('REGISTER_GET_DISTINCTS', this.ilId);
    },
    async okulGetir() {
      await this.$store.dispatch('REGISTER_GET_SCHOOLS', this.ilceId);
    },
    async sinifGetir() {
      await this.$store.dispatch('GET_CLASSES_BRANCH', this.subeId);
    },

    buyukHarf() {
      this.sube = this.sube.toUpperCase();
    },
    alerts1() {
      this.modal = true;
    },
    onceki() {
      if (this.adim3) {
        this.adim3 = false;
        this.adim2 = true;
      } else if (this.adim2) {
        this.adim2 = false;
        this.adim1 = true;
      }
    },
    captchaKontrol() {
      if (this.captcha != '' && this.captcha.length == 8) {
        this.kayit();
      } else {
        this.$swal({
          icon: 'info',
          title: 'Lüften Doğru',
          text: 'Captcha kodunu giriniz',
          confirmButtonText: 'Kapat',
        });
      }
    },
    sifreKontrol() {
      if (this.sifre == '' || this.sifreTekrar == '') {
        this.$swal({
          icon: 'warning',
          title: 'Min. 6 karakter olarak',
          text: 'Şifre Giriniz',
          confirmButtonText: 'Kapat',
        });
      } else if (this.sifre == this.sifreTekrar && this.sifreTekrar != '') {
        if (this.sifre.length <= 5) {
          this.$swal({
            icon: 'warning',
            title: 'Min. 6 karakter giriniz',
            text: 'Kısa Şifre',
            confirmButtonText: 'Kapat',
          });
        } else {
          this.captchaKontrol();
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Şifreler Uyuşmuyor',
          text: 'Lüften girdiğiniz şifreleri kontrol ediniz.',
          confirmButtonText: 'Kapat',
        });
      }
    },
    dolulukKontrol() {
      if (this.adim1) {
        if (
          this.ad == '' ||
          this.soyad == '' ||
          this.eposta == '' ||
          this.telefon == ''
        ) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: 'Boş alan bırakmayınız',
            confirmButtonText: 'Kapat',
          });
        } else if (this.telefon.length != 10) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: '10 Haneli telefon numarası giriniz',
            confirmButtonText: 'Kapat',
          });
        } else if (
          this.telefon.substring(0, 2) != '50' &&
          this.telefon.substring(0, 2) != '53' &&
          this.telefon.substring(0, 2) != '54' &&
          this.telefon.substring(0, 2) != '55'
        ) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: 'Hatalı telefon numarası girdiniz',
            confirmButtonText: 'Kapat',
          });
        } else if (this.telefonKontrol != null) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: 'Telefon kullanılmaktadır',
            confirmButtonText: 'Kapat',
          }).then(() => {
            this.adim1 = true;
            this.adim2 = false;
            this.adim3 = false;
          });
        } else {
          this.adim();
        }
      } else if (this.adim2) {
        if (
          this.ilId == null ||
          this.ilceId == null ||
          this.okulId == null ||
          this.sinifId == null ||
          this.okulNo == '' ||
          this.sube == ''
        ) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: 'Boş alan bırakmayınız',
            confirmButtonText: 'Kapat',
          });
        } else if (this.okulNo.length >= 7) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: 'Okul numaranız en fazla 6 karakter olabilir',
            confirmButtonText: 'Kapat',
          });
        } else if (this.sube.length >= 3) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: 'Şubeniz en fazla iki karakterli olabilir',
            confirmButtonText: 'Kapat',
          });
        } else {
          this.adim();
        }
      }
    },
    adim() {
      if (this.adim1) {
        this.adim1 = false;
        this.adim2 = true;
      } else if (this.adim2) {
        this.adim2 = false;
        this.adim3 = true;
      }
    },
    async kayit() {
      let _post = {
        kullaniciAdi: this.eposta,
        ad: this.ad,
        soyad: this.soyad,
        telefon: this.telefon,
        eposta: this.eposta,
        sifre: this.sifre,
        sirketId: this.sirketId,
        subeId: this.subeId,
        sinifId: this.sinifId,
        sehirId: this.ilId,
        ilceId: this.ilceId,
        okulId: this.okulId,
        // sinifGrubu: this.studentClass
        //   .filter((x) => x.sinifId == this.sinifId)
        //   .map((x) => {
        //     return x.sinifGrubu;
        //   })[0],
        sinifGrubu: this.siniflar.find((x) => x.sinifId == this.sinifId)
          .sinifGrubu,
        // sinifGrubu: this.sinifId,
        subeniz: this.sinifGrubunaGoreSaatListesi.find((x) => x.id == this.sube)
          .saat,
        okulNo: this.okulNo,
        captchaCode: this.captcha.toUpperCase(),
        captchaEncryptedValue: this.captchaCap,
        kayitPlatform: window.location.hostname,
      };
      // console.log(_post);
      // return 0;

      await this.$store.dispatch('REGISTER_USER_CORPORATE', _post).then(() => {
        if (this.kayitOnay.status == 400) {
          this.$swal({
            icon: 'warning',
            title: 'Dikkat !',
            text: this.kayitOnay.data,
            confirmButtonText: 'Kapat',
          }).then(() => {
            if (this.kayitOnay.data == 'Eposta kullanılmaktadır.') {
              this.adim1 = true;
              this.adim2 = false;
              this.adim3 = false;
            }
          });
        } else if (this.kayitOnay.status == 200) {
          this.$swal({
            icon: 'success',
            title: 'Kaydınız Oluşturulmuştur',
            text: 'Telefon onayı için yönlendirileceksiniz',
            confirmButtonText: 'Yönlendir',
          }).then(() => {
            location.href = '/dogrula/' + this.kayitOnay.data;
          });
        }
      });
    },
  },
  computed: {
    sinifGrubunaGoreSaatListesi() {
      const seciliSinifGrubu =
        this.sinifId != null
          ? this.siniflar.find((x) => x.sinifId == this.sinifId).sinifGrubu
          : 0;

      const saatListesi = this.sinifSaatListesi.filter(
        (x) => x.sinifGrubu == seciliSinifGrubu
      );

      saatListesi.sort((a, b) => a.saat.localeCompare(b.saat));
      return saatListesi;
    },
    subeler() {
      return this.$store.getters.companyClasses;
    },
    siniflar() {
      return this.$store.getters.classesBranch;
    },
    captchaCode() {
      return this.$store.getters.captchaSms;
    },
    captchaCap() {
      return this.$store.getters.captchaEncrypted;
    },
    iller() {
      return this.$store.getters.cities;
    },
    ilceler() {
      return this.$store.getters.distincts;
    },
    okullar() {
      return this.$store.getters.schools;
    },
    kullaniciControl() {
      return this.$store.getters.userControl;
    },
    epostaKontrol() {
      return this.$store.getters.userMail;
    },
    telefonKontrol() {
      return this.$store.getters.userPhone;
    },
    kayitOnay() {
      return this.$store.getters.registerUser;
    },
  },
  watch: {
    // telefon: function (newQuestion, oldQuestion) {
    //   console.log(typeof this.telefon);
    //   console.log(newQuestion, oldQuestion);
    // },
  },
};
</script>
